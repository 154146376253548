.root {
    position: relative;
    padding-top: 0px;
    bottom: -60px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    left: 0;
    right: 0;
    z-index: 10;
    gap: 40px;
    position: absolute;
}

.text {
    font-size: 30px;
    padding-top: 150px;
}

.heading {
    font-size: 90px;
    line-height: 100px;
}

.startTrigger {
    position: absolute;
    bottom: 0px;
    height: 400px;
    width: 400px;
    z-index: -999;
}

@media only screen and (max-width: 1279px) {
    .root {
        padding-bottom: 0px;
        padding-top: 9px;
    }

    .heading {
        font-size: 37px;
        line-height: 50px;
    }

    .text {
        padding-top: 146px;
        font-size: 20px;
    }

    .container {
        gap: 30px;
    }
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1440px) {
}
