@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik/GraphikThin.otf");
    font-weight: 100;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik/GraphikRegular.otf");
    font-weight: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik/GraphikLight.otf");
    font-weight: 300;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik/GraphikMedium.otf");
    font-weight: 500;
}

html {
    scroll-behavior: smooth;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.reveal-anim {
    opacity: 0;
}

.fade-container {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.fade-up {
    opacity: 0;
    transform: translateY(80px);
}

.fade-up-xl {
    opacity: 0;
    transform: translateY(100px);
}

.fade-in {
    opacity: 0;
}

.reveal-clip-anim {
    transform: translateY(115px);
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}
