.root {
    position: relative;
    text-align: center;
    overflow: hidden;
    padding-bottom: 0px;
    height: fit-content;
}

.text {
    font-size: 30px;
}

.heading {
    font-size: 90px;
    line-height: 100px;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 200px;
    z-index: 10;
    gap: 50px;
    padding-top: 900px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.waterbg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-image: url("../../../images/about/bg3.png");
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.25;
    height: 100%;
}

.waterbg2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../images/about/bg2.png");
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    margin: auto;
    height: 100%;
}

.startTrigger {
    display: block;
    position: absolute;
    top: -906px;
    width: 100px;
    height: 200vh;
}

.endtrigger {
    position: absolute;
    bottom: 0px;
}

.splashberg {
    margin: auto;
    position: relative;
    max-width: 1920px;
    height: 760px;
}

.splash {
    width: 300%;
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -80px;
    z-index: 20;
}

.splashTrigger {
    position: absolute;
    top: 30vh;
    height: 100vh;
}

.water {
    position: relative;
    height: 1350px;
}

.iceberg {
    position: absolute;
    z-index: -1;
    bottom: -780px;
    left: 0;
    right: 0;
    width: 1000px;
    margin: auto;
}

.startTrigger {
    position: absolute;
    top: 200px;
    height: 100vh;
}

@media only screen and (max-width: 1400px) {
    .iceberg {
        width: 1000px;
        bottom: -780px;
    }

    .splash {
        bottom: -5%;
        width: 300%;
    }

    .splashberg {
        height: 1200px;
        max-width: 2560px;
    }
}

@media only screen and (max-width: 1279px) {
    .heading {
        font-size: 37px;
        line-height: 50px;
        padding-bottom: 150px;
    }

    .text {
        font-size: 20px;
    }

    .water {
        height: 100vh;
    }

    .startTrigger {
        height: 100vh;
    }

    .splashTrigger {
        top: 0vh;
    }

    .content {
        padding-bottom: 20px;
        z-index: 10;
        gap: 20px;
        padding-top: 0px;
    }

    .splashberg {
        height: 780px;
    }
}

@media only screen and (max-width: 1000px) {
    .iceberg {
        width: 600px;
        bottom: -480px;
    }

    .splash {
        bottom: -3.5%;
    }

    .splashberg {
        height: 580px;
    }
}

@media only screen and (max-width: 600px) {
    .iceberg {
        width: 400px;
        bottom: -320px;
    }

    .splash {
        bottom: -2.5%;
    }

    .splashberg {
        height: 480px;
    }
}

@media only screen and (max-width: 468px) {
    .iceberg {
        width: 526px;
        max-width: 200% !important;
        bottom: -372px;
        left: -70px;
    }

    .splash {
        max-width: 400% !important;
        position: absolute;
        left: -200px;
        right: -200px;
        margin: auto;
    }

    .text {
        font-size: 20px;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1440px) {
}
