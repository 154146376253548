body {
    background-color: black;
    color: white;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
