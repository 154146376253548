.root {
    max-width: 1920px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

@media only screen and (min-width: 1920px) {
    .root {
        overflow: unset;
    }
}
