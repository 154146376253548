.root {
    height: 174vh;
    min-height: 20px;
    position: relative;
    text-align: center;
    line-height: 900%;
}

.text {
    position: absolute;
    font-size: 30px;
    z-index: 10;
    left: 0;
    right: 0;
}

.heading {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 90px;
    line-height: 100px;
    font-weight: 400;
}

.light {
    display: block;
    max-width: 1024px;
    width: 90%;
    position: absolute;
    top: -460px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.planet {
    display: block;
    max-width: 830px;
    width: 90%;
    position: absolute;
    top: -130px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
}

.endtrigger {
    position: absolute;
    bottom: 0px;
}

.asteroid1 {
    position: absolute;
    top: 730px;
    right: 22%;
}

.lightTrigger {
    position: absolute;
    top: 100px;
    height: 80vh;
}

.textTrigger {
    position: absolute;
    top: 50vh;
    height: 150vh;
    min-height: 2160px;
}

@media only screen and (max-width: 768px) {
    .root {
        height: 60vh;
        min-height: unset;
        line-height: 1400%;
    }

    .heading {
        font-size: 37px;
        line-height: 50px;
    }

    .text {
        font-size: 20px;
    }

    .asteroid1 {
        position: absolute;
        width: 140px;
        right: 10%;
        z-index: 10;
    }

    .lightTrigger {
        height: 50vh;
    }

    .textTrigger {
        top: 60vh;
        height: 1220px;
    }

    .planet {
        width: 396px;
    }

    .light {
        width: 396px;
        top: -250px;
    }
}

@media only screen and (min-width: 1900px) {
    .root {
        height: 175vh;
    }
}

@media only screen and (width: 1600px) {
    .root {
        height: 177vh;
    }
}

@media only screen and (width: 1680px) {
    .root {
        height: 174vh;
    }
}

@media only screen and (width: 1440px) {
    .root {
        height: 178vh;
    }
}
