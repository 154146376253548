.root {
    min-height: 2533px;
    height: 100vh;
    position: relative;
    text-align: center;
    scroll-snap-align: center;
    top: 0;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1080px;
}

.text {
    font-size: 30px;
}

.heading {
    font-size: 90px;
    line-height: 100px;
}

.lightTrigger {
    display: block;
    position: absolute;
    bottom: 900px;
    width: 100px;
    height: 200px;
}

.content {
    height: 1080px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    z-index: 10;
    gap: 40px;
}

.planet {
    max-width: 1240px;
    position: absolute;
    right: -300px;
    bottom: 0;
}

.asteroid {
    max-width: 150px;
    position: absolute;
    right: 100px;
    bottom: 100px;
}

.light {
    max-width: 1240px;
    position: absolute;
    z-index: 2;
}

.explosion {
    max-width: 1240px;
    position: absolute;
    right: -78px;
    bottom: 170px;
    z-index: 2;
}

.startTrigger {
    display: block;
    position: absolute;
    bottom: 963px;
    width: 100px;
    height: 200px;
}

.endtrigger {
    position: absolute;
    bottom: 648px;
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 1279px) {
    .root {
        height: 200vh;
        min-height: unset;
    }

    .container {
        height: 800px;
        min-height: unset;
    }
    .heading {
        font-size: 37px;
        line-height: 50px;
        z-index: 10;
    }

    .text {
        font-size: 20px;
    }

    .light {
        max-width: 620px;
        position: absolute;
        z-index: 2;
    }

    .planet {
        max-width: 620px;
        position: absolute;
        right: -200px;
        bottom: 0;
    }

    .explosion {
        max-width: 620px;
        position: absolute;
        right: -228px;
        bottom: -40px;
        z-index: 2;
    }
    .content {
        gap: 10px;
        padding-top: 200px;
    }
}

@media only screen and (min-width: 1920px) {
    .root {
        min-height: 2390px;
    }
}

@media only screen and (width: 1600px) {
    /*this is working for 1440x900 */
    .root {
        min-height: 2200px;
    }

    .endtrigger {
        bottom: -200px;
    }

    .lightTrigger {
        bottom: 119px;
    }
}

@media only screen and (width: 1680px) {
    .root {
        min-height: 2324px;
    }
}

@media only screen and (max-width: 768px) {
    .lightTrigger {
        bottom: 300px;
    }
}

@media only screen and (width: 1440px) {
    .root {
        min-height: 2000px;
    }

    .lightTrigger {
        bottom: 400px;
    }

    .endtrigger {
        bottom: 0px;
    }
}
