.root {
    height: 100vh;
    position: relative;
    text-align: center;
}

.text {
    font-size: 30px;
}

.heading {
    font-size: 90px;
    font-weight: 400;
}

.content {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    z-index: 10;
    gap: 40px;
}

.asteroid {
    display: block;
    width: 1024px;
    position: absolute;
    right: -500px;
    bottom: -500px;
}

.galaxy {
    display: block;
    max-width: 374px;
    position: absolute;
    left: 50px;
    bottom: 50px;
}

@media only screen and (max-width: 1500px) {
    .asteroid {
        width: 800px;
    }
}

@media only screen and (max-width: 1279px) {
    .heading {
        font-size: 37px;
        line-height: 50px;
    }

    .text {
        font-size: 20px;
    }

    .galaxy {
        display: block;
        max-width: 174px;
        position: absolute;
        left: 0px;
    }

    .asteroid {
        width: 300px;
        right: -215px;
    }

    .content {
        gap: 20px;
        padding: 20px;
    }
}
